@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;0,700;0,900;1,100;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

@font-face {
  font-family: Moderat;
  font-style: normal;
  font-weight: 300;
  src: local("Moderat"), url("~Static/fonts/Moderat-Light.woff2?20241021") format("woff2"), url("~Static/fonts/Moderat-Light.woff?20241021") format("woff")
}

@font-face {
  font-family: Moderat;
  font-style: normal;
  font-weight: 400;
  src: local("Moderat"), url("~Static/fonts/Moderat-Regular.woff2?20241021") format("woff2"), url("~Static/fonts/Moderat-Regular.woff?20241021") format("woff")
}

@font-face {
  font-family: Moderat;
  font-style: normal;
  font-weight: 500;
  src: local("Moderat"), url("~Static/fonts/Moderat-Medium.woff2?20241021") format("woff2"), url("~Static/fonts/Moderat-Medium.woff?20241021") format("woff")
}

@font-face {
  font-family: Moderat;
  font-style: normal;
  font-weight: 700;
  src: local("Moderat"), url("~Static/fonts/Moderat-Bold.woff2?20241021") format("woff2"), url("~Static/fonts/Moderat-Bold.woff?20241021") format("woff")
}
