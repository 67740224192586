
                                @import '~/home/pymedi/app/pymedi/static/scss/includes/_i_mixins';
                                @import '~/home/pymedi/app/pymedi/static/scss/includes/_i_variables';
                            
*, ::before, ::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb
}

::before, ::after {
    --tw-content: ''
}

html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, segoe ui, Roboto, helvetica neue, Arial, noto sans, sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol, noto color emoji;
    font-feature-settings: normal
}

body {
    margin: 0;
    line-height: inherit
}

hr {
    height: 0;
    color: inherit;
    border-top-width: 1px
}

abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted
}

h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: inherit
}

a {
    color: inherit;
    text-decoration: inherit
}

b, strong {
    font-weight: bolder
}

code, kbd, samp, pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, liberation mono, courier new, monospace;
    font-size: 1em
}

small {
    font-size: 80%
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse
}

button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0
}

button, select {
    text-transform: none
}

button, [type=button], [type=reset], [type=submit] {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none
}

:-moz-focusring {
    outline: auto
}

:-moz-ui-invalid {
    box-shadow: none
}

progress {
    vertical-align: baseline
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

summary {
    display: list-item
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
    margin: 0
}

fieldset {
    margin: 0;
    padding: 0
}

legend {
    padding: 0
}

ol, ul, menu {
    list-style: none;
    margin: 0;
    padding: 0
}

textarea {
    resize: vertical
}

input::-moz-placeholder, textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af
}

input::placeholder, textarea::placeholder {
    opacity: 1;
    color: #9ca3af
}

button, [role=button] {
    cursor: pointer
}

:disabled {
    cursor: default
}

img, svg, video, canvas, audio, iframe, embed, object {
    display: block;
    vertical-align: middle
}

img, video {
    max-width: 100%;
    height: auto
}

[hidden] {
    display: none
}

:root {
    --ab-alpha--direction: to right;
    --ab-beta--direction: to right;
    --ab-gamma--direction: to right;
    --ab-delta--direction: to right;
    --ab-epsilon--direction: to right;
    --ab-zeta--direction: to right;
    --ab-theta--direction: to right;
    --ab-iota--direction: to right;
    --ab-kappa--direction: to right;
    --ab-lambda--direction: to right
}

@include min-screen($smd) {
    :root {
        --ab-alpha--direction: to bottom;
        --ab-beta--direction: to bottom;
        --ab-epsilon--direction: to bottom;
        --ab-zeta--direction: to bottom;
        --ab-iota--direction: to bottom;
        --ab-kappa--direction: to bottom;
        --ab-lambda--direction: to bottom
    }
}

*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: RGB(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: RGB(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

.button {
    display: inline-block;
    min-width: 12rem;
    border-radius: 9999px;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    padding-top: .625rem;
    padding-bottom: .625rem;
    text-align: center;
    font-size: 1rem;
    line-height: 1.333;
    font-weight: 700
}

.button--default {
    --tw-bg-opacity: 1;
    background-color: RGB(0 55 75/var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: RGB(239 239 239/var(--tw-text-opacity))
}

.button--default:hover {
    --tw-bg-opacity: 1;
    background-color: RGB(119 176 255/var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: RGB(0 55 75/var(--tw-text-opacity))
}

.button--accent {
    --tw-bg-opacity: 1;
    background-color: RGB(229 161 0/var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: RGB(0 55 75/var(--tw-text-opacity))
}

.button--accent:hover {
    --tw-bg-opacity: 1;
    background-color: RGB(239 239 239/var(--tw-bg-opacity))
}

.button--small-accent {
    min-width: -moz-fit-content;
    min-width: fit-content;
    --tw-bg-opacity: 1;
    background-color: RGB(229 161 0/var(--tw-bg-opacity));
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .375rem;
    padding-bottom: .375rem;
    --tw-text-opacity: 1;
    color: RGB(0 55 75/var(--tw-text-opacity))
}

.button--small-accent:hover {
    --tw-bg-opacity: 1;
    background-color: RGB(239 239 239/var(--tw-bg-opacity))
}

.button.button--log-in {
    display: none
}

@include min-screen($lg) {
    .button.button--log-in {
        display: inline-block
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
}

.fixed {
    position: fixed
}

.absolute {
    position: absolute
}

.relative {
    position: relative
}

.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.inset-x-0 {
    left: 0;
    right: 0
}

.top-0 {
    top: 0
}

.left-0 {
    left: 0
}

.top-3 {
    top: .75rem
}

.right-4 {
    right: 1rem
}

.top-6 {
    top: 1.5rem
}

.-top-4 {
    top: -1rem
}

.left-content {
    left: clamp(1.125rem, 8.3vw, 7.5rem)
}

.bottom-0 {
    bottom: 0
}

.z-20 {
    z-index: 20
}

.z-10 {
    z-index: 10
}

.order-3 {
    order: 3
}

.order-2 {
    order: 2
}

.order-1 {
    order: 1
}

.order-5 {
    order: 5
}

.mx-4 {
    margin-left: 1rem;
    margin-right: 1rem
}

.-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem
}

.mx-auto {
    margin-left: auto;
    margin-right: auto
}

.mx-content {
    margin-left: clamp(1.125rem, 8.3vw, 7.5rem);
    margin-right: clamp(1.125rem, 8.3vw, 7.5rem)
}

.mb-4 {
    margin-bottom: 1rem
}

.mb-2 {
    margin-bottom: .5rem
}

.mb-8 {
    margin-bottom: 2rem
}

.mb-3 {
    margin-bottom: .75rem
}

.mt-3 {
    margin-top: .75rem
}

.mt-4 {
    margin-top: 1rem
}

.mr-4 {
    margin-right: 1rem
}

.\!ml-4 {
    margin-left: 1rem !important
}

.mb-16 {
    margin-bottom: 4rem
}

.mt-20 {
    margin-top: 5rem
}

.mb-12 {
    margin-bottom: 3rem
}

.mt-1\.5 {
    margin-top: .375rem
}

.mt-1 {
    margin-top: .25rem
}

.mt-5 {
    margin-top: 1.25rem
}

.mt-7 {
    margin-top: 1.75rem
}

.mb-6 {
    margin-bottom: 1.5rem
}

.mt-16 {
    margin-top: 4rem
}

.mt-8 {
    margin-top: 2rem
}

.mt-14 {
    margin-top: 3.5rem
}

.mt-9 {
    margin-top: 2.25rem
}

.mt-10 {
    margin-top: 2.5rem
}

.mt-6 {
    margin-top: 1.5rem
}

.ml-12 {
    margin-left: 3rem
}

.mt-2 {
    margin-top: .5rem
}

.mb-10 {
    margin-bottom: 2.5rem
}

.mb-5 {
    margin-bottom: 1.25rem
}

.mt-12 {
    margin-top: 3rem
}

.block {
    display: block
}

.inline-block {
    display: inline-block
}

.flex {
    display: flex
}

.grid {
    display: grid
}

.hidden {
    display: none
}

.h-full {
    height: 100%
}

.h-4 {
    height: 1rem
}

.h-16 {
    height: 4rem
}

.h-screen {
    height: 100vh
}

.h-72 {
    height: 18rem
}

.h-3 {
    height: .75rem
}

.h-5\.5 {
    height: 1.325rem
}

.h-5 {
    height: 1.25rem
}

.h-px {
    height: 1px
}

.h-48 {
    height: 12rem
}

.h-24 {
    height: 6rem
}

.min-h-hero {
    min-height: 90vh
}

.min-h-72 {
    min-height: 18rem
}

.min-h-\[200px\] {
    min-height: 200px
}

.w-full {
    width: 100%
}

.w-8 {
    width: 2rem
}

.w-6 {
    width: 1.5rem
}

.w-4 {
    width: 1rem
}

.w-16 {
    width: 4rem
}

.w-\[110px\] {
    width: 110px
}

.w-screen {
    width: 100vw
}

.w-24 {
    width: 6rem
}

.w-72 {
    width: 18rem
}

.w-content {
    width: calc(100vw - clamp(2.25rem, 16.6vw, 15rem))
}

.w-5 {
    width: 1.25rem
}

.w-3 {
    width: .75rem
}

.w-5\.5 {
    width: 1.325rem
}

.w-9 {
    width: 2.25rem
}

.w-10 {
    width: 2.5rem
}

.w-cardicon {
    width: 4.375rem
}

.w-44 {
    width: 11rem
}

.w-36 {
    width: 9rem
}

.max-w-\[130px\] {
    max-width: 130px
}

.max-w-md {
    max-width: 28rem
}

.max-w-2\.5xl {
    max-width: 45rem
}

.max-w-xs {
    max-width: 20rem
}

.max-w-2md {
    max-width: 30rem
}

.max-w-\[36rem\] {
    max-width: 36rem
}

.max-w-lg {
    max-width: 32rem
}

.flex-none {
    flex: none
}

.flex-auto {
    flex: auto
}

.flex-initial {
    flex: initial
}

.flex-1 {
    flex: 1
}

.shrink-0 {
    flex-shrink: 0
}

.grow {
    flex-grow: 1
}

.origin-center {
    transform-origin: center
}

.-translate-y-full {
    --tw-translate-y: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.-translate-x-full {
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-y-12 {
    --tw-translate-y: 3rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer {
    cursor: pointer
}

.snap-x {
    scroll-snap-type: x var(--tw-scroll-snap-strictness)
}

.snap-mandatory {
    --tw-scroll-snap-strictness: mandatory
}

.grid-cols-\[1fr\2c 2fr\] {
    grid-template-columns:1fr 2fr
}

.grid-cols-\[1fr\2c 1fr\] {
    grid-template-columns:1fr 1fr
}

.grid-cols-2 {
    grid-template-columns:repeat(2, minmax(0, 1fr))
}

.grid-cols-1 {
    grid-template-columns:repeat(1, minmax(0, 1fr))
}

.flex-row {
    flex-direction: row
}

.flex-col {
    flex-direction: column
}

.flex-wrap {
    flex-wrap: wrap
}

.place-content-center {
    place-content: center
}

.place-items-center {
    place-items: center
}

.items-center {
    align-items: center
}

.justify-center {
    justify-content: center
}

.justify-between {
    justify-content: space-between
}

.gap-8 {
    gap: 2rem
}

.gap-10 {
    gap: 2.5rem
}

.gap-1 {
    gap: .25rem
}

.gap-12 {
    gap: 3rem
}

.gap-2 {
    gap: .5rem
}

.gap-9 {
    gap: 2.25rem
}

.gap-x-8 {
    -moz-column-gap: 2rem;
    column-gap: 2rem
}

.gap-y-12 {
    row-gap: 3rem
}

.gap-y-16 {
    row-gap: 4rem
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse))
}

.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.375rem * var(--tw-space-x-reverse));
    margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.25rem * var(--tw-space-x-reverse));
    margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse))
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse))
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse))
}

.self-center {
    align-self: center
}

.overflow-hidden {
    overflow: hidden
}

.overflow-x-scroll {
    overflow-x: scroll
}

.rounded-lg {
    border-radius: .5rem
}

.rounded {
    border-radius: .25rem
}

.rounded-xl {
    border-radius: .75rem
}

.rounded-md {
    border-radius: .375rem
}

.rounded-full {
    border-radius: 9999px
}

.border {
    border-width: 1px
}

.border-y {
    border-top-width: 1px;
    border-bottom-width: 1px
}

.border-t {
    border-top-width: 1px
}

.border-b {
    border-bottom-width: 1px
}

.border-l {
    border-left-width: 1px
}

.border-white {
    --tw-border-opacity: 1;
    border-color: RGB(255 255 255/var(--tw-border-opacity))
}

.border-accentb {
    --tw-border-opacity: 1;
    border-color: RGB(128 158 161/var(--tw-border-opacity))
}

.border-grey {
    --tw-border-opacity: 1;
    border-color: RGB(225 228 232/var(--tw-border-opacity))
}

.border-light {
    --tw-border-opacity: 1;
    border-color: RGB(239 239 239/var(--tw-border-opacity))
}

.bg-accentb {
    --tw-bg-opacity: 1;
    background-color: RGB(128 158 161/var(--tw-bg-opacity))
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: RGB(255 255 255/var(--tw-bg-opacity))
}

.bg-dark {
    --tw-bg-opacity: 1;
    background-color: RGB(0 55 75/var(--tw-bg-opacity))
}

.bg-light {
    --tw-bg-opacity: 1;
    background-color: RGB(239 239 239/var(--tw-bg-opacity))
}

.bg-tertiary {
    --tw-bg-opacity: 1;
    background-color: RGB(68 119 127/var(--tw-bg-opacity))
}

.bg-secondary {
    --tw-bg-opacity: 1;
    background-color: RGB(119 176 255/var(--tw-bg-opacity))
}

.bg-primary {
    --tw-bg-opacity: 1;
    background-color: RGB(50 120 128/var(--tw-bg-opacity))
}

.object-cover {
    -o-object-fit: cover;
    object-fit: cover
}

.p-2 {
    padding: .5rem
}

.px-content {
    padding-left: clamp(1.125rem, 8.3vw, 7.5rem);
    padding-right: clamp(1.125rem, 8.3vw, 7.5rem)
}

.py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem
}

.py-header {
    padding-top: clamp(1.5rem, 3vh, 2rem);
    padding-bottom: clamp(1.5rem, 3vh, 2rem)
}

.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}

.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
}

.px-12 {
    padding-left: 3rem;
    padding-right: 3rem
}

.px-16 {
    padding-left: 4rem;
    padding-right: 4rem
}

.px-3 {
    padding-left: .75rem;
    padding-right: .75rem
}

.px-stats {
    padding-left: clamp(4.25rem, 6.7vw, 7.8rem);
    padding-right: clamp(4.25rem, 6.7vw, 7.8rem)
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}

.py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem
}

.py-30 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem
}

.py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem
}

.px-8 {
    padding-left: 2rem;
    padding-right: 2rem
}

.py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
}

.pt-5 {
    padding-top: 1.25rem
}

.pt-4 {
    padding-top: 1rem
}

.pt-3 {
    padding-top: .75rem
}

.pb-4 {
    padding-bottom: 1rem
}

.pb-2 {
    padding-bottom: .5rem
}

.pt-16 {
    padding-top: 4rem
}

.pt-24 {
    padding-top: 6rem
}

.pb-12 {
    padding-bottom: 3rem
}

.pt-8 {
    padding-top: 2rem
}

.pl-5 {
    padding-left: 1.25rem
}

.pt-12 {
    padding-top: 3rem
}

.pb-8 {
    padding-bottom: 2rem
}

.pt-40 {
    padding-top: 10rem
}

.pb-30 {
    padding-bottom: 7.5rem
}

.pt-36 {
    padding-top: 9rem
}

.pb-20 {
    padding-bottom: 5rem
}

.pt-2 {
    padding-top: .5rem
}

.pb-11 {
    padding-bottom: 2.75rem
}

.pt-28 {
    padding-top: 7rem
}

.pr-4 {
    padding-right: 1rem
}

.pb-16 {
    padding-bottom: 4rem
}

.pt-20 {
    padding-top: 5rem
}

.pb-28 {
    padding-bottom: 7rem
}

.pb-7 {
    padding-bottom: 1.75rem
}

.pb-24 {
    padding-bottom: 6rem
}

.text-left {
    text-align: left
}

.text-center {
    text-align: center
}

.font-body {
    font-family: $fontQuaternary;
}

.text-base {
    font-size: 1.125rem;
    line-height: 1.333
}

.text-sm {
    font-size: 1rem;
    line-height: 1.333
}

.text-3xl {
    font-size: clamp(1.5625rem, 2.0833vw, 2rem);
    line-height: 1.333
}

.text-\[7\.75rem\] {
    font-size: 7.75rem
}

.text-xl {
    font-size: clamp(1.375rem, 1.5278vw, 1.5rem);
    line-height: 1.333
}

.text-xs {
    font-size: .875rem;
    line-height: 1.4
}

.text-4xl {
    font-size: clamp(1.875rem, 2.7778vw, 3rem);
    line-height: 1.333
}

.text-2xl {
    font-size: clamp(1.5rem, 1.8056vw, 1.75rem);
    line-height: 1.333
}

.text-\[13\.875rem\] {
    font-size: 13.875rem
}

.text-7xl {
    font-size: 4.375rem
}

.font-light {
    font-weight: 300
}

.font-bold {
    font-weight: 700
}

.font-normal {
    font-weight: 400
}

.leading-none {
    line-height: 1
}

.leading-tight {
    line-height: 1.25
}

.text-dark {
    --tw-text-opacity: 1;
    color: RGB(0 55 75/var(--tw-text-opacity))
}

.text-white {
    --tw-text-opacity: 1;
    color: RGB(255 255 255/var(--tw-text-opacity))
}

.text-accentb {
    --tw-text-opacity: 1;
    color: RGB(128 158 161/var(--tw-text-opacity))
}

.text-secondary {
    --tw-text-opacity: 1;
    color: RGB(119 176 255/var(--tw-text-opacity))
}

.text-primary {
    --tw-text-opacity: 1;
    color: RGB(50 120 128/var(--tw-text-opacity))
}

.line-clamp-4 {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
}

.underline {
    text-decoration-line: underline
}

.placeholder-dark::-moz-placeholder {
    --tw-placeholder-opacity: 1;
    color: RGB(0 55 75/var(--tw-placeholder-opacity))
}

.placeholder-dark::placeholder {
    --tw-placeholder-opacity: 1;
    color: RGB(0 55 75/var(--tw-placeholder-opacity))
}

.opacity-25 {
    opacity: .25
}

.opacity-0 {
    opacity: 0
}

.opacity-100 {
    opacity: 1
}

.drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px RGB(0 0 0 / 0.04)) drop-shadow(0 4px 3px RGB(0 0 0 / 0.1));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: 150ms
}

.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: 150ms
}

.delay-200 {
    transition-delay: 200ms
}

.duration-700 {
    transition-duration: 700ms
}

.duration-500 {
    transition-duration: 500ms
}

.duration-1000 {
    transition-duration: 1000ms
}

.duration-200 {
    transition-duration: 200ms
}

.ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1)
}

.ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1)
}

.ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.hero > [class*=accent-bar--] {
    z-index: 20
}

[class*=accent-bar--].hide-on-mobile {
    display: none
}

@include min-screen($smd) {
    [class*=accent-bar--].hide-on-mobile {
        display: block
    }
}

.accent-bar--alpha {
    --ab--stop-1: 31%;
    --ab--stop-2: 41%;
    --ab--stop-3: 47%;
    --ab--stop-4: 82%;
    position: absolute;
    left: 0;
    top: 0;
    height: .625rem;
    width: 100%
}

@include min-screen($smd) {
    .accent-bar--alpha {
        height: 100%;
        width: 1.25rem
    }
}

.accent-bar--alpha {
    background: linear-gradient(var(--ab-alpha--direction), #77b0ff, #77b0ff var(--ab--stop-1), #e5a100 var(--ab--stop-1), #e5a100 var(--ab--stop-2), #809ea1 var(--ab--stop-2), #809ea1 var(--ab--stop-3), #efefef var(--ab--stop-3), #efefef var(--ab--stop-4), #77b0ff var(--ab--stop-4), #77b0ff)
}

.accent-bar--beta {
    --ab--stop-1: 21%;
    --ab--stop-2: 28%;
    --ab--stop-3: 32%;
    --ab--stop-4: 67%;
    position: absolute;
    left: 0;
    top: 0;
    height: .625rem;
    width: 100%
}

@include min-screen($smd) {
    .accent-bar--beta {
        height: 100%;
        width: 1.25rem
    }
}

.accent-bar--beta {
    background: linear-gradient(var(--ab-beta--direction), #77b0ff, #77b0ff var(--ab--stop-1), #e5a100 var(--ab--stop-1), #e5a100 var(--ab--stop-2), #efefef var(--ab--stop-2), #efefef var(--ab--stop-3), #809ea1 var(--ab--stop-3), #809ea1 var(--ab--stop-4), #327880 var(--ab--stop-4), #327880)
}

.accent-bar--gamma {
    --ab--stop-1: 12%;
    --ab--stop-2: 41%%;
    --ab--stop-3: 50%;
    --ab--stop-4: 76%;
    --ab--stop-5: 79%;
    --ab--stop-6: 84%;
    position: absolute;
    left: 0;
    top: 0;
    height: .625rem;
    width: 100%
}

@include min-screen($smd) {
    .accent-bar--gamma {
        height: 1.25rem
    }
}

.accent-bar--gamma {
    background: linear-gradient(var(--ab-gamma--direction), #e5a100, #e5a100 var(--ab--stop-1), #809ea1 var(--ab--stop-1), #809ea1 var(--ab--stop-2), #327880 var(--ab--stop-2), #327880 var(--ab--stop-3), #00374b var(--ab--stop-3), #00374b var(--ab--stop-4), #efefef var(--ab--stop-4), #efefef var(--ab--stop-5), #e5a100 var(--ab--stop-5), #e5a100 var(--ab--stop-6), #77b0ff var(--ab--stop-6), #77b0ff)
}

.accent-bar--delta {
    --ab--stop-1: 12%;
    --ab--stop-2: 41%;
    --ab--stop-3: 50%;
    --ab--stop-4: 76%;
    --ab--stop-5: 79%;
    --ab--stop-6: 84%;
    position: absolute;
    left: 0;
    top: 0;
    height: .625rem;
    width: 100%
}

@include min-screen($smd) {
    .accent-bar--delta {
        height: 1.25rem
    }
}

.accent-bar--delta {
    background: linear-gradient(var(--ab-delta--direction), #e5a100, #e5a100 var(--ab--stop-1), #00374b var(--ab--stop-1), #00374b var(--ab--stop-2), #77b0ff var(--ab--stop-2), #77b0ff var(--ab--stop-3), #327880 var(--ab--stop-3), #327880 var(--ab--stop-4), #00374b var(--ab--stop-4), #00374b var(--ab--stop-5), #e5a100 var(--ab--stop-5), #e5a100 var(--ab--stop-6), #77b0ff var(--ab--stop-6), #77b0ff)
}

.accent-bar--epsilon {
    --ab--stop-1: 52%;
    --ab--stop-2: 58%;
    --ab--stop-3: 68%;
    position: absolute;
    left: 0;
    top: 0;
    height: .625rem;
    width: 100%
}

@include min-screen($smd) {
    .accent-bar--epsilon {
        height: 100%;
        width: 1.25rem
    }
}

.accent-bar--epsilon {
    background: linear-gradient(var(--ab-epsilon--direction), #efefef, #efefef var(--ab--stop-1), #e5a100 var(--ab--stop-1), #e5a100 var(--ab--stop-2), #77b0ff var(--ab--stop-2), #77b0ff var(--ab--stop-3), #00374b var(--ab--stop-3), #00374b)
}

.accent-bar--zeta {
    --ab--stop-1: 32%;
    --ab--stop-2: 43%;
    --ab--stop-3: 72%;
    --ab--stop-4: 75%;
    --ab--stop-5: 86%;
    position: absolute;
    left: 0;
    top: 0;
    height: .625rem;
    width: 100%
}

@include min-screen($smd) {
    .accent-bar--zeta {
        height: 100%;
        width: 1.25rem
    }
}

.accent-bar--zeta {
    background: linear-gradient(var(--ab-epsilon--direction), #809ea1, #809ea1 var(--ab--stop-1), #327880 var(--ab--stop-1), #327880 var(--ab--stop-2), #e5a100 var(--ab--stop-2), #e5a100 var(--ab--stop-3), #efefef var(--ab--stop-3), #efefef var(--ab--stop-4), #00374b var(--ab--stop-4), #00374b var(--ab--stop-5), #77b0ff var(--ab--stop-5), #77b0ff)
}

.accent-bar--theta {
    --ab--stop-1: 12%;
    --ab--stop-2: 41%;
    --ab--stop-3: 50%;
    --ab--stop-4: 76%;
    --ab--stop-5: 79%;
    --ab--stop-6: 84%;
    position: absolute;
    left: 0;
    top: 0;
    height: .625rem;
    width: 100%
}

@include min-screen($smd) {
    .accent-bar--theta {
        height: 1.25rem
    }
}

.accent-bar--theta {
    background: linear-gradient(var(--ab-delta--direction), #e5a100, #e5a100 var(--ab--stop-1), #809ea1 var(--ab--stop-1), #809ea1 var(--ab--stop-2), #327880 var(--ab--stop-2), #327880 var(--ab--stop-3), #77b0ff var(--ab--stop-3), #77b0ff var(--ab--stop-4), #efefef var(--ab--stop-4), #efefef var(--ab--stop-5), #e5a100 var(--ab--stop-5), #e5a100 var(--ab--stop-6), #809ea1 var(--ab--stop-6), #809ea1)
}

.accent-bar--iota {
    --ab--stop-1: 52%;
    --ab--stop-2: 58%;
    --ab--stop-3: 68%;
    position: absolute;
    left: 0;
    top: 0;
    height: .625rem;
    width: 100%
}

@include min-screen($smd) {
    .accent-bar--iota {
        height: 100%;
        width: 1.25rem
    }
}

.accent-bar--iota {
    background: linear-gradient(var(--ab-iota--direction), #e5a100, #e5a100 var(--ab--stop-1), #809ea1 var(--ab--stop-1), #809ea1 var(--ab--stop-2), #77b0ff var(--ab--stop-2), #77b0ff var(--ab--stop-3), #00374b var(--ab--stop-3), #00374b)
}

.accent-bar--kappa {
    --ab--stop-1: 52%;
    --ab--stop-2: 58%;
    --ab--stop-3: 68%;
    position: absolute;
    left: 0;
    top: 0;
    height: .625rem;
    width: 100%
}

@include min-screen($smd) {
    .accent-bar--kappa {
        height: 100%;
        width: 1.25rem
    }
}

.accent-bar--kappa {
    background: linear-gradient(var(--ab-kappa--direction), #efefef, #efefef var(--ab--stop-1), #809ea1 var(--ab--stop-1), #809ea1 var(--ab--stop-2), #77b0ff var(--ab--stop-2), #77b0ff var(--ab--stop-3), #e5a100 var(--ab--stop-3), #e5a100)
}

ul.styled-list, ol.styled-list {
    margin-top: 1.25rem
}

ul.styled-list li, ol.styled-list li {
    position: relative;
    padding-left: 1.5rem
}

ul.styled-list li::before, ol.styled-list li::before {
    position: absolute;
    left: .25rem;
    top: .5rem;
    height: .5rem;
    width: .5rem;
    border-radius: 9999px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    content: ''
}

ul.styled-list.styled-list--accent li::before, ol.styled-list.styled-list--accent li::before {
    --tw-bg-opacity: 1;
    background-color: RGB(229 161 0/var(--tw-bg-opacity))
}

ul.styled-list.styled-list--accentb li::before, ol.styled-list.styled-list--accentb li::before {
    --tw-bg-opacity: 1;
    background-color: RGB(128 158 161/var(--tw-bg-opacity))
}

ul.styled-list.styled-list--primary li::before, ol.styled-list.styled-list--primary li::before {
    --tw-bg-opacity: 1;
    background-color: RGB(50 120 128/var(--tw-bg-opacity))
}

ul.styled-list.styled-list--secondary li::before, ol.styled-list.styled-list--secondary li::before {
    --tw-bg-opacity: 1;
    background-color: RGB(119 176 255/var(--tw-bg-opacity))
}

ol.styled-list {
    counter-reset: counter
}

ol.styled-list li {
    counter-increment: counter
}

ol.styled-list li::before {
    --tw-text-opacity: 1;
    color: RGB(255 255 255/var(--tw-text-opacity));
    content: counter(counter)
}

.content-list {
    -moz-column-rule: 1px solid #fff;
    column-rule: 1px solid #fff
}

.header-container.scrolled {
    --tw-bg-opacity: 1;
    background-color: RGB(255 255 255/var(--tw-bg-opacity))
}

[x-data=headerNav].is-closed {
    position: relative
}

[x-data=headerNav] nav {
    align-items: center;
    font-weight: 300
}

@include min-screen($smd) {
    [x-data=headerNav] nav > :not([hidden]) ~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1.5rem * var(--tw-space-x-reverse));
        margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))
    }

    [x-data=headerNav] nav {
        font-weight: 400
    }
}

[x-data=headerNav].is-open nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: clamp(1.125rem, 8.3vw, 7.5rem);
    padding-right: clamp(1.125rem, 8.3vw, 7.5rem)
}

.main-nav {
    display: flex;
    font-size: clamp(1.5625rem, 2.0833vw, 2rem);
    line-height: 1.333
}

@include min-screen($lg) {
    .main-nav {
        align-items: center
    }

    .main-nav > :not([hidden]) ~ :not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1.5rem * var(--tw-space-x-reverse));
        margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))
    }

    .main-nav {
        font-size: 1rem;
        line-height: 1.333
    }
}

.main-nav {
    flex-direction: column
}

@include min-screen($lg) {
    .main-nav {
        flex-direction: row
    }
}

.main-nav > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse))
}

@include min-screen($lg) {
    .main-nav > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px * var(--tw-space-y-reverse))
    }
}

.main-nav {
    border-bottom-width: 1px;
    --tw-border-opacity: 1;
    border-color: RGB(0 55 75/var(--tw-border-opacity))
}

@include min-screen($lg) {
    .main-nav {
        border-width: 0
    }
}

.main-nav {
    padding-bottom: 1rem
}

@include min-screen($lg) {
    .main-nav {
        padding-bottom: 0
    }
}

.main-nav {
    text-align: center
}

.is-open .main-nav {
    width: 100%
}

.main-nav > a {
    border-top-width: 1px;
    --tw-border-opacity: 1;
    border-color: RGB(0 55 75/var(--tw-border-opacity));
    padding-top: 1rem
}

@include min-screen($lg) {
    .main-nav > a {
        border-width: 0;
        padding-top: 0
    }
}

.main-nav > a:hover {
    --tw-text-opacity: 1;
    color: RGB(229 161 0/var(--tw-text-opacity))
}

[x-ref=slider] {
    scrollbar-width: none;
    -ms-overflow-style: none
}

[x-ref=slider]::-webkit-scrollbar {
    background: 0 0;
    width: 0
}

.grid.grid--2x2 {
    grid-template-columns:repeat(1, minmax(0, 1fr));
    -moz-column-gap: 2rem;
    column-gap: 2rem
}

@include min-screen($smd) {
    .grid.grid--2x2 {
        grid-template-columns:repeat(2, minmax(0, 1fr))
    }
}

.grid.grid--3x3 {
    grid-auto-rows: minmax(0, 1fr);
    grid-template-columns:repeat(1, minmax(0, 1fr))
}

@include min-screen($smd) {
    .grid.grid--3x3 {
        grid-template-columns:repeat(2, minmax(0, 1fr))
    }
}

@include min-screen($lg) {
    .grid.grid--3x3 {
        grid-template-columns:repeat(3, minmax(0, 1fr))
    }
}

.grid.divided--connected {
    border-top-width: 1px
}

@include min-screen($lg) {
    .grid.divided--connected {
        border-top-width: 0
    }
}

.grid.divided--connected > .grid-item::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    z-index: 10;
    content: var(--tw-content);
    height: 1px
}

.grid.divided--connected > .grid-item::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    z-index: 10;
    content: var(--tw-content);
    width: 1px
}

.grid.divided--white {
    --tw-border-opacity: 1;
    border-color: RGB(255 255 255/var(--tw-border-opacity))
}

.grid.divided--white > .grid-item::before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: RGB(255 255 255/var(--tw-bg-opacity))
}

.grid.divided--white > .grid-item::after {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: RGB(255 255 255/var(--tw-bg-opacity))
}

.grid.divided--light {
    --tw-border-opacity: 1;
    border-color: RGB(239 239 239/var(--tw-border-opacity))
}

.grid.divided--light > .grid-item::before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: RGB(239 239 239/var(--tw-bg-opacity))
}

.grid.divided--light > .grid-item::after {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: RGB(239 239 239/var(--tw-bg-opacity))
}

.grid.divided--spaced {
    overflow: hidden
}

.grid.divided--spaced > .grid-item::after {
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: -1px;
    content: var(--tw-content);
    height: 1px
}

.grid.divided--spaced > .grid-item::before {
    position: absolute;
    top: 1rem;
    bottom: 1rem;
    right: -1rem;
    content: var(--tw-content);
    width: 1px
}

@include min-screen($smd) {
    .grid.grid--2x2 > .grid-item:first-child {
        padding-top: 1rem;
        padding-bottom: 3rem
    }

    .grid.grid--2x2 > .grid-item:nth-of-type(2) {
        padding-top: 1rem;
        padding-bottom: 3rem
    }
}

.grid.grid--2x2 > .grid-item {
    padding-top: 4rem;
    padding-bottom: 4rem
}

@include min-screen($smd) {
    .grid.grid--2x2 > .grid-item {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem
    }

    .grid.grid--2x2 > .grid-item:last-child {
        padding-top: 3rem;
        padding-bottom: 1rem
    }

    .grid.grid--2x2 > .grid-item:nth-last-child(2) {
        padding-top: 3rem;
        padding-bottom: 1rem
    }
}

.grid.grid--3x3 > .grid-item {
    height: 100%;
    width: 100%
}

@include min-screen($smd) {
    .grid.grid--3x3 > .grid-item:last-child:nth-child(odd) {
        grid-column: span 2/span 2
    }
}

@include min-screen($lg) {
    .grid.grid--3x3 > .grid-item:last-child:nth-child(odd) {
        grid-column: auto
    }
}

.tile--bg-media > figure, .tile--bg-media > img, .tile--bg-media > figure > img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.image-gallery > figure > img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.before\:absolute::before {
    content: var(--tw-content);
    position: absolute
}

.before\:inset-0::before {
    content: var(--tw-content);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.before\:inset-y-4::before {
    content: var(--tw-content);
    top: 1rem;
    bottom: 1rem
}

.before\:-right-px::before {
    content: var(--tw-content);
    right: -1px
}

.before\:-left-16::before {
    content: var(--tw-content);
    left: -4rem
}

.before\:top-4::before {
    content: var(--tw-content);
    top: 1rem
}

.before\:-bottom-8::before {
    content: var(--tw-content);
    bottom: -2rem
}

.before\:-left-4::before {
    content: var(--tw-content);
    left: -1rem
}

.before\:top-0::before {
    content: var(--tw-content);
    top: 0
}

.before\:bottom-4::before {
    content: var(--tw-content);
    bottom: 1rem
}

.before\:-z-10::before {
    content: var(--tw-content);
    z-index: -10
}

.before\:block::before {
    content: var(--tw-content);
    display: block
}

.before\:w-px::before {
    content: var(--tw-content);
    width: 1px
}

.before\:w-5::before {
    content: var(--tw-content);
    width: 1.25rem
}

.before\:bg-light::before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: RGB(239 239 239/var(--tw-bg-opacity))
}

.before\:bg-white::before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: RGB(255 255 255/var(--tw-bg-opacity))
}

.before\:pt-\[65\.7\%\]::before {
    content: var(--tw-content);
    padding-top: 65.7%
}

.before\:pb-\[100\%\]::before {
    content: var(--tw-content);
    padding-bottom: 100%
}

.after\:absolute::after {
    content: var(--tw-content);
    position: absolute
}

.after\:inset-x-0::after {
    content: var(--tw-content);
    left: 0;
    right: 0
}

.after\:-bottom-px::after {
    content: var(--tw-content);
    bottom: -1px
}

.after\:-right-4::after {
    content: var(--tw-content);
    right: -1rem
}

.after\:top-0::after {
    content: var(--tw-content);
    top: 0
}

.after\:bottom-4::after {
    content: var(--tw-content);
    bottom: 1rem
}

.after\:h-px::after {
    content: var(--tw-content);
    height: 1px
}

.after\:w-px::after {
    content: var(--tw-content);
    width: 1px
}

.after\:bg-light::after {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: RGB(239 239 239/var(--tw-bg-opacity))
}

.first\:border-t:first-child {
    border-top-width: 1px
}

.last\:before\:w-0:last-child::before {
    content: var(--tw-content);
    width: 0
}

.hover\:text-accent:hover {
    --tw-text-opacity: 1;
    color: RGB(229 161 0/var(--tw-text-opacity))
}

.hover\:before\:-inset-x-4:hover::before {
    content: var(--tw-content);
    left: -1rem;
    right: -1rem
}

.hover\:before\:-top-4:hover::before {
    content: var(--tw-content);
    top: -1rem
}

.hover\:before\:bottom-0:hover::before {
    content: var(--tw-content);
    bottom: 0
}

.hover\:before\:w-auto:hover::before {
    content: var(--tw-content);
    width: auto
}

.hover\:before\:bg-white:hover::before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: RGB(255 255 255/var(--tw-bg-opacity))
}

.hover\:before\:shadow-video:hover::before {
    content: var(--tw-content);
    --tw-shadow: 0 4px 4px rgba(0, 0, 0, 0.16), 0 -1px 1px rgba(0, 0, 0, 0.16);
    --tw-shadow-colored: 0 4px 4px var(--tw-shadow-color), 0 -1px 1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:after\:\!w-0:hover::after {
    content: var(--tw-content);
    width: 0 !important
}

@include min-screen(640px) {
    .sm\:ml-20 {
        margin-left: 5rem
    }

    .sm\:flex-auto {
        flex: auto
    }
}

@include min-screen($smd) {
    .md\:absolute {
        position: absolute
    }

    .md\:top-2 {
        top: .5rem
    }

    .md\:mx-content {
        margin-left: clamp(1.125rem, 8.3vw, 7.5rem);
        margin-right: clamp(1.125rem, 8.3vw, 7.5rem)
    }

    .md\:mt-8 {
        margin-top: 2rem
    }

    .md\:mt-5 {
        margin-top: 1.25rem
    }

    .md\:mb-16 {
        margin-bottom: 4rem
    }

    .md\:block {
        display: block
    }

    .md\:w-1\/2 {
        width: 50%
    }

    .md\:basis-1\/2 {
        flex-basis: 50%
    }

    .md\:columns-2 {
        -moz-columns: 2;
        columns: 2
    }

    .md\:grid-cols-2 {
        grid-template-columns:repeat(2, minmax(0, 1fr))
    }

    .md\:grid-cols-5 {
        grid-template-columns:repeat(5, minmax(0, 1fr))
    }

    .md\:grid-cols-3 {
        grid-template-columns:repeat(3, minmax(0, 1fr))
    }

    .md\:grid-cols-4 {
        grid-template-columns:repeat(4, minmax(0, 1fr))
    }

    .md\:place-content-start {
        place-content: start
    }

    .md\:justify-start {
        justify-content: flex-start
    }

    .md\:gap-px {
        gap: 1px
    }

    .md\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(4rem * var(--tw-space-y-reverse))
    }

    .md\:border-t-0 {
        border-top-width: 0
    }

    .md\:p-0 {
        padding: 0
    }

    .md\:py-10 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem
    }

    .md\:px-10 {
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }

    .md\:px-32 {
        padding-left: 8rem;
        padding-right: 8rem
    }

    .md\:pt-32 {
        padding-top: 8rem
    }

    .md\:pl-content {
        padding-left: clamp(1.125rem, 8.3vw, 7.5rem)
    }

    .md\:pr-20 {
        padding-right: 5rem
    }

    .md\:pt-0 {
        padding-top: 0
    }

    .md\:pb-0 {
        padding-bottom: 0
    }

    .md\:pb-16 {
        padding-bottom: 4rem
    }

    .md\:pt-24 {
        padding-top: 6rem
    }

    .md\:text-left {
        text-align: left
    }

    .md\:text-3xl {
        font-size: clamp(1.5625rem, 2.0833vw, 2rem);
        line-height: 1.333
    }

    .md\:text-\[13\.875rem\] {
        font-size: 13.875rem
    }

    .md\:before\:block::before {
        content: var(--tw-content);
        display: block
    }

    .md\:before\:h-screen::before {
        content: var(--tw-content);
        height: 100vh
    }

    .md\:after\:inset-x-4::after {
        content: var(--tw-content);
        left: 1rem;
        right: 1rem
    }

    .md\:first\:border-t-0:first-child {
        border-top-width: 0
    }

    .md\:odd\:after\:w-0:nth-child(odd)::after {
        content: var(--tw-content);
        width: 0
    }

    .md\:even\:after\:w-px:nth-child(even)::after {
        content: var(--tw-content);
        width: 1px
    }
}

@include min-screen($lg) {
    .lg\:absolute {
        position: absolute
    }

    .lg\:left-1\/2 {
        left: 50%
    }

    .lg\:order-1 {
        order: 1
    }

    .lg\:order-3 {
        order: 3
    }

    .lg\:order-2 {
        order: 2
    }

    .lg\:mx-auto {
        margin-left: auto;
        margin-right: auto
    }

    .lg\:mb-0 {
        margin-bottom: 0
    }

    .lg\:mt-24 {
        margin-top: 6rem
    }

    .lg\:ml-\[50vw\] {
        margin-left: 50vw
    }

    .lg\:mt-20 {
        margin-top: 5rem
    }

    .lg\:mb-20 {
        margin-bottom: 5rem
    }

    .lg\:mt-0 {
        margin-top: 0
    }

    .lg\:ml-32 {
        margin-left: 8rem
    }

    .lg\:mt-16 {
        margin-top: 4rem
    }

    .lg\:mb-24 {
        margin-bottom: 6rem
    }

    .lg\:-mr-content {
        margin-right: calc(clamp(1.125rem, 8.3vw, 7.5rem) * -1)
    }

    .lg\:mr-16 {
        margin-right: 4rem
    }

    .lg\:block {
        display: block
    }

    .lg\:flex {
        display: flex
    }

    .lg\:hidden {
        display: none
    }

    .lg\:w-\[130px\] {
        width: 130px
    }

    .lg\:w-1\/3 {
        width: 33.333333%
    }

    .lg\:w-\[80\%\] {
        width: 80%
    }

    .lg\:w-\[50vw\] {
        width: 50vw
    }

    .lg\:w-auto {
        width: auto
    }

    .lg\:max-w-\[44rem\] {
        max-width: 44rem
    }

    .lg\:flex-auto {
        flex: auto
    }

    .lg\:flex-\[1_0_33\%\] {
        flex: 1 0 33%
    }

    .lg\:basis-1\/3 {
        flex-basis: 33.333333%
    }

    .lg\:basis-1\/4 {
        flex-basis: 25%
    }

    .lg\:columns-3 {
        -moz-columns: 3;
        columns: 3
    }

    .lg\:grid-cols-3 {
        grid-template-columns:repeat(3, minmax(0, 1fr))
    }

    .lg\:grid-cols-4 {
        grid-template-columns:repeat(4, minmax(0, 1fr))
    }

    .lg\:grid-cols-1 {
        grid-template-columns:repeat(1, minmax(0, 1fr))
    }

    .lg\:grid-cols-2 {
        grid-template-columns:repeat(2, minmax(0, 1fr))
    }

    .lg\:grid-cols-\[2fr\2c 1fr\] {
        grid-template-columns:2fr 1fr
    }

    .lg\:grid-cols-\[1fr\2c 2fr\] {
        grid-template-columns:1fr 2fr
    }

    .lg\:flex-nowrap {
        flex-wrap: nowrap
    }

    .lg\:place-content-center {
        place-content: center
    }

    .lg\:gap-8 {
        gap: 2rem
    }

    .lg\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(6rem * var(--tw-space-y-reverse))
    }

    .lg\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(4rem * var(--tw-space-y-reverse))
    }

    .lg\:self-auto {
        align-self: auto
    }

    .lg\:border-0 {
        border-width: 0
    }

    .lg\:border-y {
        border-top-width: 1px;
        border-bottom-width: 1px
    }

    .lg\:border-t {
        border-top-width: 1px
    }

    .lg\:py-16 {
        padding-top: 4rem;
        padding-bottom: 4rem
    }

    .lg\:py-0 {
        padding-top: 0;
        padding-bottom: 0
    }

    .lg\:px-16 {
        padding-left: 4rem;
        padding-right: 4rem
    }

    .lg\:px-0 {
        padding-left: 0;
        padding-right: 0
    }

    .lg\:px-content {
        padding-left: clamp(1.125rem, 8.3vw, 7.5rem);
        padding-right: clamp(1.125rem, 8.3vw, 7.5rem)
    }

    .lg\:pt-20 {
        padding-top: 5rem
    }

    .lg\:pb-1 {
        padding-bottom: .25rem
    }

    .lg\:pr-32 {
        padding-right: 8rem
    }

    .lg\:pl-content {
        padding-left: clamp(1.125rem, 8.3vw, 7.5rem)
    }

    .lg\:pb-0 {
        padding-bottom: 0
    }

    .lg\:pr-0 {
        padding-right: 0
    }

    .lg\:pt-12 {
        padding-top: 3rem
    }

    .lg\:pr-12 {
        padding-right: 3rem
    }

    .lg\:pt-32 {
        padding-top: 8rem
    }

    .lg\:pb-24 {
        padding-bottom: 6rem
    }

    .lg\:pt-28 {
        padding-top: 7rem
    }

    .lg\:pt-0 {
        padding-top: 0
    }

    .lg\:text-left {
        text-align: left
    }

    .lg\:text-base {
        font-size: 1.125rem;
        line-height: 1.333
    }

    .lg\:text-sm {
        font-size: 1rem;
        line-height: 1.333
    }

    .lg\:font-normal {
        font-weight: 400
    }

    .lg\:opacity-0 {
        opacity: 0
    }

    .lg\:before\:w-px::before {
        content: var(--tw-content);
        width: 1px
    }

    .lg\:\[\&\>div\:last-child\]\:mr-\[10vw\] > div:last-child {
        margin-right: 10vw
    }

    .lg\:\[\&\>\:nth-child\(2\)\]\:order-first > :nth-child(2) {
        order: -9999
    }

    .lg\:\[\&\:nth-child\(3n-2\)\]\:after\:w-0:nth-child(3n-2)::after {
        content: var(--tw-content);
        width: 0
    }

    .lg\:\[\&\:nth-child\(3n-1\)\]\:after\:w-0:nth-child(3n-1)::after {
        content: var(--tw-content);
        width: 0
    }

    .lg\:\[\&\:nth-child\(3n\)\]\:after\:w-px:nth-child(3n)::after {
        content: var(--tw-content);
        width: 1px
    }
}

.\[\&\>div\:first-child\]\:border-t-8 > div:first-child {
    border-top-width: 8px
}

.\[\&\:nth-child\(6n-5\)\>div\:first-child\]\:border-accent:nth-child(6n-5) > div:first-child {
    --tw-border-opacity: 1;
    border-color: RGB(229 161 0/var(--tw-border-opacity))
}

.\[\&\:nth-child\(6n-4\)\>div\:first-child\]\:border-accentb:nth-child(6n-4) > div:first-child {
    --tw-border-opacity: 1;
    border-color: RGB(128 158 161/var(--tw-border-opacity))
}

.\[\&\:nth-child\(6n-3\)\>div\:first-child\]\:border-secondary:nth-child(6n-3) > div:first-child {
    --tw-border-opacity: 1;
    border-color: RGB(119 176 255/var(--tw-border-opacity))
}

.\[\&\:nth-child\(6n-2\)\>div\:first-child\]\:border-dark:nth-child(6n-2) > div:first-child {
    --tw-border-opacity: 1;
    border-color: RGB(0 55 75/var(--tw-border-opacity))
}

.\[\&\:nth-child\(6n-1\)\>div\:first-child\]\:border-primary:nth-child(6n-1) > div:first-child {
    --tw-border-opacity: 1;
    border-color: RGB(50 120 128/var(--tw-border-opacity))
}

.\[\&\:nth-child\(6n\)\>div\:first-child\]\:border-accent:nth-child(6n) > div:first-child {
    --tw-border-opacity: 1;
    border-color: RGB(229 161 0/var(--tw-border-opacity))
}

.\[\&\:hover\>svg\]\:opacity-100:hover > svg {
    opacity: 1
}

.\[\&\>p\]\:mb-4 > p {
    margin-bottom: 1rem
}

.about-bio{
    background: linear-gradient(to bottom, rgba(0, 55, 75, 1) 0%, rgba(0, 0, 0, 0) 100%);
    background-clip: text;
    color: transparent;
    max-height: 100px;
    overflow: hidden;
    transition: all 0.8s, flex-grow 0s;
    margin-bottom: 10px;
}
.about-bio-expanded{
    background: inherit;
    background-clip: inherit;
    color: inherit;
    max-height: inherit;
    overflow: inherit;
    flex-grow: 0;
}
.about-bio-toggle{
    display: block;
    margin-top: auto;
    color: #327882;
}
.about-bio-expanded + .about-bio-toggle {
    margin-top: 0;
}
